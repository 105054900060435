<template>
    <div>
        <div class="py-3 text-xs text-center text-black border-t border-black border-solid">
            Website maintained by Activa Media. All Rights Reserved.
        </div>
    </div>

</template>

<script>
export default {

}
</script>

<style>

</style>