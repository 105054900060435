<template>
  <div class="">
    <!-- Header -->
    <TheHeader />

    <!-- Banner -->
    <TheBanner />

    <!-- About Us -->
    <div class="bg-black">
      <div class="container py-6 mx-auto">
        <div class="flex flex-wrap items-center justify-center">
          <div class="w-full lg:w-1/2">
            <img
              src="/images/about-us.jpg"
              alt="About Us"
              class="mx-auto mb-5 text-center lg:mb-0"
            />
          </div>
          <div class="w-full px-5 text-center lg:w-1/2 lg:text-left">
            <h1 class="pb-4 text-2xl text-yellow-300 md:text-4xl poppin-bold">
              About Us
            </h1>
            <h2 class="text-yellow-300 open-bold">
              Hoe Soon Renovation & Plumbing Sanitary Works
            </h2>
            <p class="text-sm text-white open-reg md:text-base">
              Selangor since 2004. We are a company who pride ourselves in
              detail and quality of work. We are fully qualified tradesmen who
              have at least 19 years of experience in the profession. We are
              based in Selangor and have established a perfect team who all have
              great pride in the work to create the best finish possible.
              <br /><br />
              We offer a range of product styles and qualities to meet the needs
              of our customers. We specialize in bathroom, kitchen, air
              conditioning, solar, ventilation, drainage and all plumbing works.
              <br /><br />
              We manage complete projects for our customers and their
              requirements and become the point of contact to ensure delivery.
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Services -->
    <div class="py-6 bg-gray-800">
      <div class="container mx-auto">
        <h1
          class="pb-4 text-2xl text-center text-yellow-300 md:text-4xl poppin-bold"
        >
          Our Services
        </h1>
        <div class="flex flex-wrap justify-center">
          <div
            class="w-1/2 px-3 text-center md:w-1/3 lg:w-1/4"
            v-for="(item, i) in serVices"
            :key="i"
          >
            <img :src="item.image" :alt="item.alt" class="mx-auto" />
            <p class="pt-4 pb-6 text-yellow-300 open-reg">{{ item.alt }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Before & After -->
    <div class="bg-gray-600">
      <div class="container py-6 mx-auto">
        <h1
          class="pb-4 text-2xl text-center text-yellow-300 md:text-4xl poppin-bold"
        >
          Our Featured Projects
        </h1>
        <div class="text-white">
          <carousel
            :autoplay="true"
            :autoplayHoverPause="false"
            :autoplayTimeout="2500"
            :loop="true"
            :per-page="2"
            :navigationEnabled="false"
          >
            <slide>
              <img
                src="/images/product-1.jpg"
                alt="Before & AFter"
                class="w-full px-1 mx-auto text-center"
              />
            </slide>
            <slide>
              <img
                src="/images/product-2.jpg"
                alt="Before & AFter"
                class="w-full px-1 mx-auto text-center"
              />
            </slide>
            <slide>
              <img
                src="/images/product-3.jpg"
                alt="Before & AFter"
                class="w-full px-1 mx-auto text-center"
              />
            </slide>
          </carousel>
        </div>
        <a
          href="tel:+60122897945"
          class="block w-64 py-3 mx-auto mt-10 text-lg text-center text-yellow-300 bg-black rounded-lg poppin-semi"
          >Call Us Now</a
        >
      </div>
    </div>

    <!-- Why Choose Us -->
    <div class="bg-gray-700">
      <div class="container py-6 mx-auto text-center">
        <h1
          class="pb-4 text-2xl text-center text-yellow-300 md:text-4xl poppin-bold"
        >
          Why Choose Us
        </h1>
        <div class="flex flex-wrap justify-center">
          <div
            class="w-1/2 px-3 md:w-1/4"
            v-for="(item, i) in chooseUs"
            :key="i"
          >
            <img :src="item.image" :alt="item.alt" class="mx-auto text-white" />
            <p class="pt-4 pb-6 text-yellow-300 open-reg">{{ item.alt }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Testimonial -->
    <div class="text-center bg-black">
      <div class="container py-6 mx-auto">
        <h1
          class="pb-4 text-2xl text-center text-yellow-300 md:text-4xl poppin-bold"
        >
          Our Customer's Experience With Us
        </h1>
        <carousel
          :autoplay="true"
          :autoplayTimeout="2500"
          :autoplayHoverPause="false"
          :loop="true"
          :perPageCustom="[
            [0, 1],
            [768, 2],
            [1024, 3],
          ]"
        >
          <slide class="px-3">
            <div
              class="relative py-5 text-center text-white border-2 border-yellow-300 cusHeight"
            >
              <p class="px-2">
                I was carefully searching for right contractor for my house
                renovation. But none of those I found were good until I meet Hoe
                Soon which is recommended by my family mamber. I'm so happy with
                their after-sale service. Even when I called them after a year,
                they still came to my new house to fix some small issue of
                renovation.
              </p>
              <br /><br />
              <h1 class="absolute left-0 right-0 bottom-6 yant-bold">
                Ms Lee Siew Mei
              </h1>
            </div>
          </slide>
          <slide class="px-3">
            <div
              class="relative py-5 text-center text-white border-2 border-yellow-300 cusHeight"
            >
              <p class="px-2">
                Reasonable price and responsibility. Great service from them
              </p>
              <br /><br />
              <h1 class="absolute left-0 right-0 bottom-6 yant-bold">
                Encik Halim
              </h1>
            </div>
          </slide>
          <slide class="px-3">
            <div
              class="relative py-5 text-center text-white border-2 border-yellow-300 cusHeight"
            >
              <p class="px-2">
                I would absolutely recommend Hoe Soon as your one stop
                contractor for all your construction needs. He is honest and
                professional. His work is of high quality and very clean. He
                also always has many creative ideas and suggestions. Thank you
                so much Alan!
              </p>
              <br /><br />
              <h1 class="absolute left-0 right-0 bottom-6 yant-bold">
                Mr Shan
              </h1>
            </div>
          </slide>
          <slide class="px-3">
            <div
              class="relative py-5 text-center text-white border-2 border-yellow-300 cusHeight"
            >
              <p class="px-2">
                Trusted & save cost renovation. Their service is reliable,
                responsive & professional. Highly recommended!!!
              </p>
              <br /><br />
              <h1 class="absolute left-0 right-0 bottom-6 yant-bold">
                Ms Elaine Yap
              </h1>
            </div>
          </slide>
          <slide class="px-3">
            <div
              class="relative py-5 text-center text-white border-2 border-yellow-300 cusHeight"
            >
              <p class="px-2">
                The renovation team did a really great job in my home last week.
                They considered our needs and worked like professionals. I will
                definitely call them again when I need renovation work. Alan is
                very friendly and easy to deal with him during our discussion
              </p>
              <br /><br />
              <h1 class="absolute left-0 right-0 bottom-6 yant-bold">
                Sangkar
              </h1>
            </div>
          </slide>
        </carousel>

        <!-- CTA -->
        <div class="px-3 py-6 text-white">
          <h1 class="pb-4 text-2xl text-center md:text-4xl poppin-bold">
            Let Us Help You NOW!
          </h1>
          <p class="open-reg">
            Contact Us to find out more information about our contruction
            services in Malaysia
          </p>
          <a
            href="tel:+60122897945"
            class="block w-64 py-3 mx-auto mt-10 text-sm text-center bg-yellow-500 rounded-lg poppin-semi"
            ><i class="fas fa-phone-alt"></i> Call 0122897945</a
          >
          <a
            href="https://wa.me/60122897945"
            class="block w-64 py-3 mx-auto mt-5 text-sm text-center bg-yellow-500 rounded-lg poppin-semi"
            >Click to WhatsApp</a
          >
        </div>
      </div>
    </div>

    <!-- Enquiry -->
    <div
      class="py-6 bg-center bg-no-repeat bg-cover"
      style="background-image: url(../images/enquiry.jpg)"
    >
      <div class="container mx-auto text-center">
        <div class="flex flex-wrap justify-center">
          <div class="w-full md:w-1/2"></div>
          <div class="w-full pt-4 md:w-1/2 bgEnquiry">
            <h1 class="pb-2 text-4xl poppin-bold" id="element">
              Send An Enquiry
            </h1>
            <div class="px-5">
              <p class="pb-3 open-reg">
                Fill out the form below and we'll get back to you as soon as
                possible
              </p>
            </div>
            <!-- feedback.activamedia.com.sg script begins here -->
            <iframe
              allowTransparency="true"
              style="min-height: 520px; height: inherit; overflow: auto"
              width="100%"
              id="contactform123"
              name="contactform123"
              marginwidth="0"
              marginheight="0"
              frameborder="0"
              src="https://feedback.activamedia.com.sg/my-contact-form-5839154.html"
            ></iframe>
            <!-- feedback.activamedia.com.sg script ends here -->
          </div>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <div class="py-6 bg-gray-700">
      <div class="container mx-auto">
        <div class="justify-center md:flex md:flex-wrap">
          <div
            class="w-full pb-4 text-center text-gray-200 md:w-1/3 md:pb-0 md:pl-4"
          >
            <img
              src="/images/logo.png"
              alt="Hoe Soon Renovation & Plumbing Sanitary Work"
              class="mx-auto mb-3 text-yellow-300"
            />
            <p class="pb-2 open-semi">
              No 3, Jalan USJ 7/3C,<br />
              Subang Jaya, 47610 Subang Jaya,<br />
              Selangor
            </p>
            <a href="tel:+60122897945">Tel : 012-2897945</a>
            <br />
          </div>
          <div class="w-full md:w-2/3">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.171599385016!2d101.5914091150903!3d3.0486703546114824!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc4d1a017b6119%3A0x8193d65c9c8bb68e!2sHOE%20SOON%20RENOVATION%20%26%20PLUMBING%20SANITARY%20WORKS!5e0!3m2!1sen!2smy!4v1614926487363!5m2!1sen!2smy"
              class="mx-auto"
              width="70%"
              height="auto"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <Thecopyright />
  </div>
</template>

<script>
// @ is an alias to /src
import TheHeader from "@/components/TheHeader.vue";
import TheBanner from "@/components/TheBanner.vue";
import Thecopyright from "@/components/Thecopyright.vue";

export default {
  name: "Home",
  components: {
    TheHeader,
    TheBanner,
    Thecopyright,
  },
  data() {
    return {
      serVices: [
        {
          image: "images/services-1.png",
          alt: "Cement & Plastering Works",
        },
        {
          image: "images/services-2.png",
          alt: "Painting/ Roof Painting",
        },
        {
          image: "images/services-3.png",
          alt: "Kitchen Renovation",
        },
        {
          image: "images/services-4.png",
          alt: "Plumbing & Electrical Works",
        },
        {
          image: "images/services-5.png",
          alt: "Grille & Gutter Works",
        },
        {
          image: "images/services-6.png",
          alt: "Water Pump/Water Filter",
        },
        {
          image: "images/services-7.png",
          alt: "Wall Tiles & Floor Tiles",
        },
      ],
      chooseUs: [
        {
          image: "images/choose-1.png",
          alt: "Wide Range of Services",
        },
        {
          image: "images/choose-2.png",
          alt: "More than 19 Years of Experience",
        },
        {
          image: "images/choose-3.png",
          alt: "Professional",
        },
        {
          image: "images/choose-4.png",
          alt: "Client Statisfication",
        },
      ],
    };
  },
};
</script>

<style>
/* Font */
.poppin-semi {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}
.poppin-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

.open-reg {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}
.open-semi {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
.open-bold {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}

/* max-width */
.maxWidth {
  max-width: 1920px;
}

/* custom height */
.cusHeight {
  height: 300px;
}

/* Enquiry bg */
.bgEnquiry {
  background-color: rgba(252, 211, 77, 0.5);
}
</style>
