<template>
    <div class="bg-black">
        <div class="container py-8 mx-auto md:py-5 lg:py-3">
            <div class="items-center justify-center px-6 sm:flex sm:flex-wrap sm:justify-between">
                <div class="w-full md:w-1/3">
                    <img src="/images/logo.png" alt="Hoe Soon Renovation & Plumbing Sanitary Work" class="mx-auto text-yellow-300 md:mx-0"/>
                </div>
                <div class="w-full text-center md:text-right md:w-1/3">
                    <a href="tel:+60122897945" class="text-xl text-yellow-300 md:text-2xl poppin-semi">
                        <span class="text-lg open-reg">Call Us At</span><br/>
                        <i class="fas fa-phone-alt"></i> 012-2897945
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style>
</style>