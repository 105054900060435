<template>
    <div>

        <div class="py-32 bg-center bg-no-repeat bg-cover" style="background-image: url(../images/banner.jpg)">
            <div class="container mx-auto">
                <h1 class="text-2xl text-center text-white xl:text-4xl poppin-bold textShadow"> 
                    We Have More Than 19 Years Experience In All Kind Of Renovation, Plumbing,
                    Roofing & Painting Works
                </h1>
                <a href="tel:+60122897945" class="block w-64 py-3 mx-auto mt-10 text-sm text-center text-yellow-300 bg-black rounded-lg poppin-semi">Call Us Now</a>
                <!-- <a v-if="!hideEnquiry" href="#" class="block w-64 py-3 mx-auto mt-10 text-sm text-center text-yellow-300 bg-black rounded-lg poppin-semi" v-scroll-to="{el:'#element',duration: 1500,}">ENQUIRY NOW</a> -->
            </div>
        </div>

    </div>
</template>

<script>
import Vue from 'vue'
import VueScrollTo from 'vue-scrollto'
Vue.use(VueScrollTo)

export default {
    props: {
        hideEnquiry: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style>
/* Shadow */
.textShadow{text-shadow: 2px 2px 2px #000;}
</style>